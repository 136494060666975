import styled, { css } from 'styled-components';
import { below, above } from '../../../styles/tools/media';
import breakpoints from '../../../styles/settings/breakpoints';
import {
	xlTitleRegular,
	smBodyLight,
	lgTitleRegular,
} from '../../atoms/Typography/index.styled';
import { GridFullStretch } from '../../../styles/generic/container';
import { gap, spacing } from '../../../styles/settings/spacing';

type CardsProps = {
	backgroundColor?: string
};

type CopyProps = {
	singleCardView: boolean
	multipleBlocks: boolean
};

type BlockTitleProps = {
	singleCardView: boolean
	noMargin: boolean
};

export type CardsPerRow = {
	multipleBlocks?: boolean
	medium?: number
	large?: number
	extraLarge?: number
};

type CardItemProps = {
	cardsPerRow?: CardsPerRow
	multipleBlocks: boolean
};

const Cards = styled(GridFullStretch)<CardsProps>`
	background-color: ${(props) =>
		props.backgroundColor !== undefined
			? `var(--${props.backgroundColor})`
			: 'var(--secondaryBackground)'};
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};

	> a {
		grid-column: 1 / -1;
		margin-top: ${gap[32]};
		justify-self: start;
	}

	${above(
		breakpoints[960],
		() => css`
			padding-top: ${spacing[64]};
			padding-bottom: ${spacing[64]};

			> a {
				margin-top: ${gap[56]};
				justify-self: center;
			}
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			padding-top: ${spacing[80]};
			padding-bottom: ${spacing[80]};
		`
	)}
`;

const Cards__Title = styled(xlTitleRegular)``;

const Cards__TitleContainer = styled.div<any>`
	color: var(--primaryForeground);
	grid-column: 1 / -1;
	grid-row: 1;
	margin-bottom: ${gap[24]};

	${above(
		breakpoints[768],
		() => css`
			text-align: ${(props: any) =>
				props.singleCardView ? 'center' : 'inherit'};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			grid-column: 3 / span 8;
			margin-bottom: ${gap[32]};
			text-align: center;
		`
	)}

	${above(
		breakpoints[1920],
		() => css`
			margin-bottom: ${gap[12]};
		`
	)}
`;

const Cards__Copy = styled(smBodyLight)<CopyProps>`
	grid-column: 1 / -1;
	grid-row: 2;
	margin-bottom: ${(props) =>
		props.multipleBlocks ? `${gap[48]}` : `${gap[32]}`};

	${props => above(
		breakpoints[768],
		() => css`
			text-align: ${props.singleCardView ? 'center' : 'inherit'};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			grid-column: 4 / span 6;
			margin-bottom: ${gap[32]};
			text-align: center;
		`
	)}
`;

const Cards__CardBlock = styled.ul<any>`
	grid-column: 1 / -1;
	padding-left: 0;
	list-style-type: none;

	${above(
		breakpoints[768],
		() => css`
			display: ${(props: any) =>
				props.cardsPerRow.medium > 1 ? 'grid' : 'block'};
			grid-template-columns: ${(props: any) =>
				props.cardsPerRow.medium > 1 ? 'repeat(12, 1fr)' : 'none'};
			grid-column-gap: ${(props: any) =>
				props.cardsPerRow.medium > 1 ? `${gap[24]}` : 'none'};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-column-gap: ${(props: any) =>
				props.multipleBlocks && props.cardsPerRow.large > 2
					? `${gap[24]}`
					: `${gap[48]}`};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			grid-column-gap: ${(props: any) =>
				props.cardsPerRow.extraLarge !== 4 ? `${gap[48]}` : `${gap[24]}`};
		`
	)}
`;

const Cards__CardItem = styled.li<CardItemProps>`
	margin-bottom: ${(props) => props.multipleBlocks ? `${gap[16]}` : `${gap[32]}`};

	&:last-child {

		${below(
			breakpoints[768],
			() => css`
				margin-bottom: ${gap[0]};
			`
		)}
	}

	&:only-child {
		${above(
			breakpoints[768],
			() => css`
				grid-column: 4 / span 6;
			`
		)}
	}

	${props => above(
		breakpoints[768],
		() => css`
			grid-column-end: ${() => {
				if (props.cardsPerRow ) {
					if (props.cardsPerRow.medium === 2) {
						return 'span 6';
					}
					if (props.cardsPerRow.medium === 3) {
						return 'span 4';
					}
				}
				return 'span 6';

			}};
		`
	)}

	${props => above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[44]};
			grid-column-end: ${() => {
				if (props.cardsPerRow ) {
					if (props.cardsPerRow.large === 2) {
						return 'span 6';
					}
					if (props.cardsPerRow.large === 3) {
						return 'span 4';
					}
					if (props.cardsPerRow.large === 4) {
						return 'span 3';
					}
				}
				return 'span 4';

			}};
		`
	)}

	${props => above(
		breakpoints[1440],
		() => css`
			grid-column-end: ${() => {
				if (props.cardsPerRow ) {
					if (props.cardsPerRow.extraLarge === 2) {
						return 'span 6';
					}
					if (props.cardsPerRow.extraLarge === 3) {
						return 'span 4';
					}
					if (props.cardsPerRow.extraLarge === 4) {
						return 'span 3';
					}
				}
				return 'span 4';

			}};
		`
	)}
`;

const Cards__BlockTitle = styled(lgTitleRegular)<BlockTitleProps>`
	color: var(--primaryForeground);
	grid-column: 1 / -1;
	margin-bottom: ${gap[24]};
	margin-top: ${(props) => (props.noMargin ? '0' : gap[32])};

	${props => above(
		breakpoints[768],
		() => css`
			text-align: ${props.singleCardView ? 'center' : 'inherit'};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			grid-column: 3 / span 8;
			margin-bottom: ${gap[40]};
			text-align: center;
		`
	)}

	${above(
		breakpoints[1920],
		() => css`
			margin-bottom: ${gap[12]};
		`
	)}
`;

const Link = styled.a`
	grid-row: 4;
`;

export default {
	Cards,
	Cards__Title,
	Cards__Copy,
	Cards__CardBlock,
	Cards__CardItem,
	Cards__BlockTitle,
	Cards__TitleContainer,
	Link,
};
