import React, {Fragment} from 'react';
import Card from '../../molecules/Card';
import Link from '../../atoms/LinkStandard';
import Styled, { CardsPerRow } from './index.styled';

type ImageTypes = {
	src: string
	alt: string
	imageType?: string
};

type LinkTypes = {
	url: string
	title: string
	dataGACategory: string
	dataGAAction: string
	dataGALabel: string
};

type ListItemTypes = {
	id: string
	icon: string
	book?: string
	body?: string
};

type AddOnListTypes = {
	id: string
	body: string
};

type AddOnTypes = {
	dataGACategory: string
	dataGAAction: string
	dataGALabel: string
	list: Array<AddOnListTypes>
};

type CardTypes = {
	id: string
	image: ImageTypes
	title: string
	subText?: string
	copy?: string
	location?: string
	list?: Array<ListItemTypes>
	link?: LinkTypes
	addons?: AddOnTypes
};

type CardBlockTypes = {
	title?: string
	cards: Array<CardTypes>
};

type Props = {
	title: string
	copy: string
	link?: LinkTypes
	backgroundColor?: string
	cardsPerRow?: CardsPerRow
	blocks: Array<CardBlockTypes>
	hideImageOnMobile?: boolean
	anchorId?: string
};

const ShouldHaveSingleCardView = blocks => {
	let allBlocksSingleCard = false;

	blocks.forEach(block => {
		allBlocksSingleCard = block.cards.length === 1;
	});

	return allBlocksSingleCard;
};

const Cards = ({
	title,
	copy,
	blocks,
	link,
	backgroundColor,
	cardsPerRow,
	hideImageOnMobile = false,
	anchorId,
}: Props) => {
	const singleCardView = ShouldHaveSingleCardView(blocks);
	return (
		<Styled.Cards
			backgroundColor={backgroundColor}
			as="section"
			id={anchorId || ''}
		>
			{title && (
				<Styled.Cards__TitleContainer singleCardView={singleCardView}>
					<Styled.Cards__Title as="h2">{title}</Styled.Cards__Title>
				</Styled.Cards__TitleContainer>
			)}
			{copy && (
				<Styled.Cards__Copy
					multipleBlocks={blocks.length > 1}
					singleCardView={singleCardView}
				>
					{copy}
				</Styled.Cards__Copy>
			)}
			{blocks &&
				blocks.length > 0 &&
				blocks.map((cardBlock, index) => (
					<Fragment key={index}>
						{cardBlock.title && (
							<Styled.Cards__BlockTitle
								as="h3"
								noMargin={index === 0}
								singleCardView={singleCardView}
							>
								{cardBlock.title}
							</Styled.Cards__BlockTitle>
						)}
						<Styled.Cards__CardBlock
							cardsPerRow={cardsPerRow}
							multipleBlocks={blocks.length > 1}
						>
							{cardBlock.cards.map((data: CardTypes) => (
								<Styled.Cards__CardItem
									key={data.id}
									cardsPerRow={cardsPerRow}
									multipleBlocks={blocks.length > 1}
								>
									<Card {...data} hideImageOnMobile={hideImageOnMobile} />
								</Styled.Cards__CardItem>
							))}
						</Styled.Cards__CardBlock>
					</Fragment>
				))}
			{link && <Link {...link} />}
		</Styled.Cards>
	);
};

export default Cards;
