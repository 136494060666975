import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';

const List = styled.ul`
	padding-left: 0;
	list-style-type: none;

	li {
		display: grid;
		grid-template-columns: 20px 1fr;
	}

	${above(breakpoints[960], () => css`
		li {
			grid-template-columns: 24px 1fr;
		}
	`)}
`;

export const TickList = styled(List)`
	li {
		grid-gap: ${gap[16]};
	}

	svg {
		width: 100%;
		height: 20px;
	}

	svg path {
		fill: var(--primaryAccent);
	}

	${above(breakpoints[960], () => css`
		svg {
			height: 24px;
			margin-top: ${gap[4]};
		}
	`)}
`;

export const BulletList = styled(List)`
	li {
		grid-gap: ${gap[20]};

		&::before {
			content: '';
			width: 12px;
			height: 12px;
			margin-top: ${gap[12]};
			border: 2px solid var(--primaryAccent);
		}
	}
`;
