import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import {useRouter} from 'next/router';
import { pushEvent } from '@/lib/tracking';
import Browser from '@/lib/browser';
import SVG from '../../atoms/SVG';
import LinkStandard from '../../atoms/LinkStandard';
import CustomImage from '../../atoms/CustomImage';
import Styled from './index.styled';

type ImageTypes = {
	src: string;
	alt: string;
	imageType?: string;
};

type LinkTypes = {
	url: string;
	title: string;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

type ListItemTypes = {
	id: string;
	icon: string;
	book?: string;
	body?: string;
};

type AddOnListTypes = {
	id: string;
	body: string;
};

type AddOnTypes = {
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
	list: Array<AddOnListTypes>;
};

type CardTypes = {
	id: string;
	hideImageOnMobile?: boolean;
	hideListOnMobile?: boolean;
	image: ImageTypes;
	title: string;
	subText?: string;
	copy?: string;
	richCopy?: Document;
	location?: string;
	list?: Array<ListItemTypes>;
	link?: LinkTypes;
	addons?: AddOnTypes;
};

const renderListItem = (item: ListItemTypes, idx: number) => (
	<Styled.Card__ListItem key={`${item.id}${idx.toString()}`} as="li">
		<SVG name={item.icon} aria-hidden="true" />
		<span>
			{item.book && (
				<Styled.Card__ListItemBook as="strong">{item.book}</Styled.Card__ListItemBook>
			)}
			{item.body && item.body}
		</span>
	</Styled.Card__ListItem>
);

const renderAddOnItem = (item: AddOnListTypes) => (
	<Styled.Card__AddOnsListItem key={item.id} as="li">
		{item.body}
	</Styled.Card__AddOnsListItem>
);


const Card = ({
	hideImageOnMobile,
	hideListOnMobile,
	image,
	title,
	subText,
	copy,
	richCopy,
	location,
	list,
	link,
	addons,
}: CardTypes) => {
	const router = useRouter();
	// TODO: need to add id/GUID to these in the api
	const [expanded, setExpansion] = useState(false);

	const toggleExpansion = event => {
		expanded ? setExpansion(false) : setExpansion(true);
		pushEvent(event);
	};

	function navigateToLink(target: string | undefined) {
		const isExternal = target?.startsWith('http');

		if (!isExternal && target?.startsWith('#')) {
			return; // Do nothing - handled by handleClick
		}

		if (target) {
			router.push(target);
		}
	}

	return (
		<Styled.Card__Wrapper onClick={event => Browser.handleClick(event, link?.url, () => navigateToLink(link?.url))} role={link ? 'button' : undefined}>
		<Styled.Card hideImageOnMobile={hideImageOnMobile} >
			{image && <CustomImage {...image} width="300" height="200" />}

			<Styled.Card__Content>
				{title && <Styled.Card__Title>{title}</Styled.Card__Title>}

				{location && (
					<Styled.Card__Location>
						<SVG name="pointer" aria-hidden="true" /> {location}
					</Styled.Card__Location>
				)}

				{subText && <Styled.Card__SubText>{subText}</Styled.Card__SubText>}

				{copy && <Styled.Card__Copy>{copy}</Styled.Card__Copy>}

				{richCopy && <Styled.Card__Copy as='div'>{documentToReactComponents(richCopy, {
								renderText: text =>
									text.split('\n').flatMap((copyLine, i) => [i > 0 && <br />, copyLine]),
							})}</Styled.Card__Copy>}

				{list && (
					<Styled.Card__List hideListOnMobile={hideListOnMobile}>
						{list.map(renderListItem)}
					</Styled.Card__List>
				)}

				{link && (
					<Styled.Card__Link>
						<Styled.Card__LinkInner>
							<LinkStandard {...link} />
						</Styled.Card__LinkInner>
					</Styled.Card__Link>
				)}

				{addons && (
					<>
						<Styled.Card__AddOnsButton
							as="button"
							type="button"
							aria-expanded={expanded}
							data-ga-category={addons.dataGACategory}
							data-ga-action={addons.dataGAAction}
							data-ga-label={addons.dataGALabel}
							onClick={toggleExpansion}
						>
							Add-ons
							<SVG name="down" aria-hidden="true" />
						</Styled.Card__AddOnsButton>

						<Styled.Card__AddOnsList hidden={!expanded}>
							{addons.list.map(renderAddOnItem)}
						</Styled.Card__AddOnsList>
					</>
				)}
			</Styled.Card__Content>
		</Styled.Card>
		</Styled.Card__Wrapper>
	);
};

export default Card;
